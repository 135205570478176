import KanbanBoard from '@/components/KanbanBoard'
import CSelectSearch from '@/components/CSelectSearch'
import GCFilter from '@/components/GCFilter/index'
import useTaskKanban from '@/composables/useTaskKanban'

export default {
  components: { KanbanBoard, CSelectSearch, GCFilter },
  inject: ['mountedComponent'],
  data() {
    return {
      columnType: { name: 'user_id', value: this.$route.params.user_id },
    }
  },
  mounted() {
    this.fetchData({}, this.columnType)
  },
  setup() {
    const { tasks, fetchData } = useTaskKanban()
    return { tasks, fetchData }
  },
}
