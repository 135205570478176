<template>
  <CCard>
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active>
        <span>Tasks</span>
      </CBreadcrumbItem>
    </teleport>

    <CCardBody>
      <CRow class="justify-content-md-between g-3 mb-3">
        <CCol class="GCFilterMaxWidth col-12 col-md-9 class-lg-10">
          <GCFilter
            component="TaskBoard"
            @search="(val) => fetchData(val, columnType)"
          />
        </CCol>
        <CCol class="col-md-auto col-12 mt-4">
          <router-link
            :to="{
              name: 'CompanyUserTasks',
              params: { id: $route.params.id, user_id: $route.params.user_id },
            }"
            class="link-tb"
          >
            <CIcon name="cil-list" style="margin-right: 5px" />
            Table
          </router-link>
        </CCol>
      </CRow>
      <KanbanBoard :data="tasks" />
    </CCardBody>
  </CCard>
</template>

<script src="./script.js"></script>
